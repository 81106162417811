import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: '主页',
        },
        component: () => import('@/views/HomeView')
    },
    {
        path: "/link",
        name: "link",
        component: () => import('@/views/LinkUs'),
        meta: {
            title: "联系我们"
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach( (to, from, next) => {
    document.title = "广州洋海网络科技有限公司官网"
    next()
})
export default router