<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
* {
  margin: 0;
  padding:  0;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
}
</style>
